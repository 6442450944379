import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f85a5330"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "quests"
}
const _hoisted_2 = {
  key: 1,
  class: "quests"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quest = _resolveComponent("quest")!

  return (_ctx.isParticipant)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedQuests, (quest, index) => {
          return (_openBlock(), _createBlock(_component_quest, {
            key: index,
            quest: quest,
            class: "quest"
          }, null, 8, ["quest"]))
        }), 128))
      ]))
    : (_ctx.isGroom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.publishedQuests, (quest, index) => {
            return (_openBlock(), _createBlock(_component_quest, {
              key: index,
              quest: quest,
              class: "quest"
            }, null, 8, ["quest"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}