import Sidequests from "@/views/Sidequests.vue";
import TrackedQuest from "@/views/TrackedQuest.vue";
import Quests from "@/views/Quests.vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Tracked Quest",
    component: TrackedQuest,
  },
  {
    path: "/sidequests",
    name: "Sidequests",
    component: Sidequests,
  },
  {
    path: "/quests",
    name: "Quests",
    component: Quests,
  },
  // {
  //   path: "/sidequests",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "is-warning",
});

export default router;
