
import Quest from "@/components/Quest.vue";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: { Quest },
  name: "TrackedQuest",
  computed: {
    ...mapGetters([
      "sortedQuests",
      "publishedQuests",
      "isGroom",
      "isParticipant",
    ]),
  },
});
