import { reactive } from "@vue/reactivity";
import { createStore } from "vuex";
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection } from "@firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyAUY9pUfznFfaGUuJHzpe84K_fXbMD4FeM",
  authDomain: "bachelorparty-217da.firebaseapp.com",
  projectId: "bachelorparty-217da",
  storageBucket: "bachelorparty-217da.appspot.com",
  messagingSenderId: "917318549906",
  appId: "1:917318549906:web:2ad3fdea2bb7b1ceee814b",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export default createStore({
  state: {
    quests: reactive([]),
    sideQuests: reactive([]),
    sideQuestSortField: "completed",
    user: undefined,
  },
  mutations: {
    setQuests(state, payload) {
      state.quests = payload;
    },
    setSideQuests(state, payload) {
      state.sideQuests = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async loadQuests({ commit }) {
      const querySnapshot = await getDocs(collection(db, "levels"));
      const quests = [] as Array<Record<string, any>>;
      querySnapshot.forEach((doc) => {
        quests.push(doc.data());
      });

      commit("setQuests", quests);
    },
    async loadSideQuests({ commit }) {
      const querySnapshot = await getDocs(collection(db, "assignments"));
      const sideQuests = [] as Array<Record<string, any>>;
      querySnapshot.forEach((doc) => {
        sideQuests.push(doc.data());
      });

      commit("setSideQuests", sideQuests);
    },
    updateUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  getters: {
    sortedQuests(state) {
      return state.quests.sort(
        (a: { number: number }, b: { number: number }) => a.number - b.number
      );
    },
    publishedQuests(state) {
      return state.quests
        .filter((quest: { published: boolean }) => quest.published)
        .sort(
          (a: { number: number }, b: { number: number }) => a.number - b.number
        );
    },
    trackedQuest(state) {
      return state.quests
        .filter((quest: { published: boolean }) => quest.published)
        .find((quest: { tracked: boolean }) => quest.tracked);
    },
    sortedSideQuests(state) {
      return state.sideQuests.sort(
        (
          a: { completed: boolean; reward: number },
          b: { completed: boolean; reward: number }
        ) => {
          if (a.completed === b.completed) {
            return a.reward - b.reward;
          }

          return a.completed ? 1 : -1;
        }
      );
    },
    maxScore(state) {
      let score = 0;
      state.quests.forEach((quest: { reward: number }) => {
        score += quest.reward;
      });

      state.sideQuests.forEach((sideQuest: { reward: number }) => {
        score += sideQuest.reward;
      });

      return score;
    },
    score(state) {
      let score = 0;
      state.quests.forEach((quest: { score: number }) => {
        score += quest.score;
      });

      state.sideQuests.forEach((sideQuest: { score: number }) => {
        score += sideQuest.score;
      });

      return score;
    },
    isParticipant(state: Record<string, any>) {
      const email = state.user ? state.user.email : undefined;

      return email === "participant@bachelor.party";
    },
    isGroom(state: Record<string, any>) {
      const email = state.user ? state.user.email : undefined;

      return email === "hannes@bachelor.party";
    },
    completed(state: Record<string, any>) {
      return (
        state.quests.length !== 0 &&
        !state.quests.find((quest: { completed: boolean }) => !quest.completed)
      );
    },
  },
  modules: {},
});
