import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02b9b5f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sidequests nes-container"
}
const _hoisted_2 = {
  key: 0,
  class: "sidequest__status nes-btn is-warning"
}
const _hoisted_3 = {
  key: 1,
  class: "sidequest__status nes-btn is-success"
}
const _hoisted_4 = {
  key: 2,
  class: "sidequest__status nes-btn is-error"
}
const _hoisted_5 = { class: "sidequest__title" }
const _hoisted_6 = { class: "sidequest__reward" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isGroom || _ctx.isParticipant)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedSideQuests, (sideQuest, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "sidequest"
          }, [
            (!sideQuest.completed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "!"))
              : (sideQuest.score > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "V"))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, "X")),
            _createElementVNode("span", _hoisted_5, _toDisplayString(sideQuest.title), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(sideQuest.score) + "/" + _toDisplayString(sideQuest.reward) + " XP", 1)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}