import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "nes-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Quest = _resolveComponent("Quest")!

  return (_ctx.trackedQuest && (_ctx.isGroom || _ctx.isParticipant))
    ? (_openBlock(), _createBlock(_component_Quest, {
        key: 0,
        quest: _ctx.trackedQuest
      }, null, 8, ["quest"]))
    : (_ctx.isGroom || _ctx.isParticipant)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Coming soon! "))
      : _createCommentVNode("", true)
}