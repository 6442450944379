
import { defineComponent } from "vue";

export default defineComponent({
  name: "Quest",
  props: ["quest"],
  computed: {
    time() {
      if (!this.quest) {
        return "";
      }

      const date = new Date(this.quest.time * 1000);
      return (
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    },
  },
});
