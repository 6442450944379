
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Quest from "@/components/Quest.vue";

export default defineComponent({
  name: "TrackedQuest",
  computed: {
    ...mapGetters(["trackedQuest", "isGroom", "isParticipant"]),
  },
  components: { Quest },
});
