
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUY9pUfznFfaGUuJHzpe84K_fXbMD4FeM",
  authDomain: "bachelorparty-217da.firebaseapp.com",
  projectId: "bachelorparty-217da",
  storageBucket: "bachelorparty-217da.appspot.com",
  messagingSenderId: "917318549906",
  appId: "1:917318549906:web:2ad3fdea2bb7b1ceee814b",
};

export default defineComponent({
  name: "Home",
  computed: {
    ...mapState(["user", "quests"]),
    ...mapGetters([
      "trackedQuest",
      "score",
      "maxScore",
      "isGroom",
      "isParticipant",
      "completed",
    ]),
  },
  methods: {
    ...mapActions(["loadQuests", "loadSideQuests", "updateUser"]),
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.updateUser(undefined);
        });
    },
  },
  beforeMount() {
    firebase.initializeApp(firebaseConfig);

    // Initialize the FirebaseUI Widget using Firebase.
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
          },
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          return false;
        },
      },
    });

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.updateUser(user);
    });

    this.loadQuests();
    this.loadSideQuests();
  },
});
